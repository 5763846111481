import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import * as rive from "@rive-app/canvas";
import icon_lookup from "./YRIconLookup.js";

let r = null;
let q = 1;
let a = null;

Alpine.data('solvej', (serverNow, mask, autoplay) => ({
  serverNow: serverNow,
  autoplay: autoplay,
  now: new Date(serverNow),
  hidden: true,
  riveLoaded: false,
  speed: 19,
  riveValueInterval: null,
  show_bubble: false,
  playing: false,
  show_subtitles: false,
  data: [],
  otherOptions: [],
  audio: {},
  values: {
    'Talking': false,
    'TimeOfDay': null,
    'Temperature': null,
    'CloudAmount': null,
    'WindStrength': null,
    'RainAmount': null,
    'SnowAmount': null,
    // New values
    'Earmuffs': false,
    'Shades': false,
    'Raincoat': false,
    'Mask': mask,
  },
  inputs: {},
  init () {
    this.data = window.solvej_data;
    this.pickDataSet(0);
    this.loadAssets();
  },
  pickDataSet (idx) {
    clearInterval(this.riveValueInterval);
    this.pickedData = this.data[idx];
    this.otherOptions = this.data.slice();
    this.otherOptions.splice(idx,1);
    if (this.riveLoaded) this.shutUp();
    this.genLinkText(this.otherOptions);
    this.weatherSymbol = this.pickedData.rawdata.symbol;
    this.values['TimeOfDay'] = this.calcTimeOfDay(this.getTime(this.pickedData), this.pickedData);
    this.values['Temperature'] = this.pickedData.rawdata.avgAir_temperature * 4;
    this.values['CloudAmount'] = this.pickedData.rawdata.avgCloud_area_fraction;
    this.values['WindStrength'] = this.pickedData.rawdata.avgWind_speed * 2;
    let precip = this.calcPrecipitation(this.pickedData);
    this.values['RainAmount'] = precip['rain'];
    this.values['SnowAmount'] = precip['snow'];
    this.values['Raincoat'] = precip['rain'] > 0;
    this.values['Shades'] = this.pickedData.rawdata.avgAir_temperature >= 20 && this.pickedData.rawdata.avgCloud_area_fraction <= 50;
    this.values['Earmuffs'] = precip['snow'] > 0 && precip['rain'] == 0;
    if (this.riveLoaded) this.setRiveValues(this.values, this.newNumbersDataForRive());
  },
  weatherIconHTML () {
    let weatherSymbol = this.weatherSymbol + '';
    let f = icon_lookup(weatherSymbol);
    if (f === undefined && weatherSymbol.indexOf('_') !== -1) {
      f = icon_lookup(weatherSymbol.split('_')[0]);
    }
    if (f !== undefined) {
      return `<img src="/weather_svgs/${f}.svg" class="w-full h-auto" alt="${weatherSymbol}">`;
    }
    return '';
  },
  genLinkText (arr) {
    arr.forEach((e, i) => {
      let ts = e.timespan.toLowerCase();
      if (e.orderCounter == 0) {
        e.linkText = `Hvordan bliver vejret de næste par timer?`;
      } else if (ts == 'formiddag') {
        e.linkText = `Hvordan bliver vejret her til formiddag?`;
      } else if (ts == 'middag') {
        e.linkText = `Hvordan bliver vejret her til middag?`;
      } else if (ts == 'eftermiddag') {
        e.linkText = `Hvordan bliver vejret til eftermiddag?`;
      } else if (ts == 'aften') {
        e.linkText = `Hvordan bliver vejret i aften?`;
      } else if (ts == 'nat') {
        e.linkText = `Hvordan bliver vejret i nat?`;
      } else if (ts == 'morgen') {
        e.linkText = `Hvordan bliver vejret i morgen tidlig?`;
      }
    });
  },
  getTime (data) {
    let ts = data.timespan.toLowerCase();
    let time = new Date();
    time.setHours(0);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);
    if (ts == 'morgen') {
      time.setHours(7);
      time.setMinutes(30);
    } else if (ts == 'formiddag') {
      time.setHours(10);
    } else if (ts == 'middag') {
      time.setHours(12);
    } else if (ts == 'eftermiddag') {
      time.setHours(15);
      time.setMinutes(30);
    } else if (ts == 'aften') {
      time.setHours(21);
    } else if (ts == 'nat') {
      time.setHours(3);
    }
    return time;
  },
  shutUp () {
    this.stop_audio();
    this.stopSubtitles();
    window.dispatchEvent(new Event('solvej_stop'));
  },
  calcTimeOfDay (time, data) {
    const sunrise = new Date(data.rawdata.sunrise);
    const nextSunrise = new Date(sunrise.getTime());
    nextSunrise.setDate(nextSunrise.getDate() + 1);
    const sunset = new Date(data.rawdata.sunset);
    let nowT = time.getTime();
    const sunriseT = sunrise.getTime();
    const nextSunriseT = nextSunrise.getTime();
    const sunsetT = sunset.getTime();
  
    let timeOfDay = 0;
  
    if (nowT < sunriseT) { // Set the 24h period to start at sunrise
      nowT += 24*60*60*1000;
    }
    if (nowT < sunsetT) { // DAY
      timeOfDay = (nowT - sunriseT) / (sunsetT - sunriseT) * 50;
    } else { // NIGHT        
      timeOfDay = (nowT - sunsetT) / (nextSunriseT - sunsetT) * 50 + 50;
    }
    return timeOfDay;
  },
  calcPrecipitation(data) {
    let rain = 0;
    let snow = 0;
    if (data.rawdata.avgProbability_of_precipitation > 50) {
      if (data.rawdata.avgAir_temperature < -1) {
        snow = data.rawdata.avgPrecipitation_amount;
      } else if (data.rawdata.avgAir_temperature >= -1 && data.rawdata.avgAir_temperature <= 1) {
        snow = data.rawdata.avgPrecipitation_amount/2;
        rain = snow;
      } else {
        rain = data.rawdata.avgPrecipitation_amount;
      }
    }
    return {
      rain,
      snow
    }
  },
  setRiveValues (newValues, newNumbers) {
    const stateMachineInputs = r.stateMachineInputs('Solvej');
    // Immediately set all non-numerical values
    stateMachineInputs.forEach(i => {
      if (newValues[i.name] !== undefined && (newNumbers == null || i.type != 56)) {
        i.value = newValues[i.name];
      };
    });
    function adjust () {
      let allEqual = true;
      stateMachineInputs.forEach(i => {
        if (newNumbers[i.name] !== undefined && i.type == 56) {
          if (i.value != newNumbers[i.name]) {
            allEqual = false;
            if (i.value < newNumbers[i.name]) {
              i.value = Math.round(i.value + 1);
            } else {
              i.value = Math.round(i.value - 1);
            }
          }
        };
      });
      return allEqual;
    }
    if (newNumbers) {
      Object.keys(newNumbers).forEach(k => {
        newNumbers[k] = Math.round(newNumbers[k]);
      });
      this.riveValueInterval = setInterval(() => {
        if (adjust()) clearInterval(this.riveValueInterval);
      }, 33);
    }
  },
  newNumbersDataForRive () {
    const stateMachineInputs = r.stateMachineInputs('Solvej');
    const riveNumbers = {};
    stateMachineInputs.forEach(i => {
      if (i.type == 56) {
        riveNumbers[i.name] = this.values[i.name];
      };
    });
    return riveNumbers;
  },
  loadAssets () {
    this.startRive();
    this.loadAudio();
  },
  loadAudio () {
    this.data.forEach((e, i) => {
      this.audio[e.filename] = this.$refs[`audio_${i}`];
    });
  },
  startRive() {
    r = new rive.Rive({
      //src: "https://public.rive.app/hosted/102767/151364/-c3y1yf-IkWZjJ0InCooUg.riv",
      src: '/rive/solvej.riv',
      canvas: document.getElementById('solvej'),
      autoplay: true,
      stateMachines: 'Solvej',
      onLoad: () => {
        window.r = r;
        this.setRiveValues(this.values, null);
        this.onLoad();
      }
    });
  },
  play_audio_listener (evt) {
    this.shutUp();
    this.pickDataSet(evt.detail);
    this.play_audio();
  },
  stop_audio_listener (evt) {
    this.shutUp();
  },
  play_audio () {
    a = this.audio[this.pickedData.filename];
    this.playing = true;
    this.setRiveValues({'Talking': true});
    a.addEventListener('ended', () => {
      this.setRiveValues({'Talking': false});
      this.playing = false;
      window.dispatchEvent(new Event('solvej_stop'));
    });
    a.currentTime = 0;
    a.play();
    this.startSubtitles(this.pickedData.msg, true);
    window.dispatchEvent(new Event('solvej_play'));
  },
  stop_audio (fromButton = false) {
    //a = this.audio[this.pickedData.filename];
    this.playing = false;
    if (a == null) return;
    a.pause();
    a.currentTime = 0;
    if (!fromButton) {
      this.setRiveValues({'Talking': false});
    }
  },
  play_info () {
    a = this.$refs.audio_i;
    this.playing = true;
    this.setRiveValues({'Talking': true});
    a.addEventListener('ended', () => {
      this.setRiveValues({'Talking': false});
      this.playing = false;
      window.dispatchEvent(new Event('solvej_stop'));
    });
    a.currentTime = 0;
    a.play();
    this.startSubtitles("Hej. Jeg hedder Solvej Vindblæs. Jeg er TV2 Nords digitale vejrvært. Jeg får mine data fra YR og laver en nordjysk vejrstatus ud af dem med ChatGPT og så giver Elevenlabs mig min stemme. Det er altså ret smart, men det er også ret nyt, så hvis jeg opfører mig skørt, så send lige en mail til solvej@tv2nord.dk", true);
    window.dispatchEvent(new Event('solvej_play'));
  },
  onLoad () {
    this.hidden = false;
    this.riveLoaded = true;
    if (this.autoplay) {
      this.startSubtitles(this.pickedData.msg);
    }
  },
  startSubtitles (msg, withAudio = false) {
    this.show_subtitles = true;
    q++;
    this.$refs.solvej_subtitle.innerText = '';
    this.$refs.solvej_subtitle.classList.remove('hidden');
    this.setRiveValues({'Talking': true});
    this.startTypeWriter(msg, withAudio);
  },
  stopSubtitles () {
    q++;
    this.$refs.solvej_subtitle.innerText = '';
    this.$refs.solvej_subtitle.classList.add('hidden');
    this.setRiveValues({'Talking': false});
  },
  async typeWriter(msg, spanNode, q_copy) {
    let heights = {};
    let wraps = [];
    const that = this;
    return new Promise((accept) => {
      function innerTypeWriter(msg, spanNode, q_copy) {
        if (msg.length > 0 && q_copy == q) {
          let c = msg.substr(0,1);
          let c_next = msg.substr(1,1);
          spanNode.innerText = `${spanNode.innerText}${c}${c == ' ' ? '\u200C' : ''}`;
          window.requestAnimationFrame(() => {
            if (q_copy != q) return;
            let h = spanNode.offsetHeight;
            
            if (h > 0 && heights[h] === undefined) {
              heights[h] = true;
              let idx = spanNode.innerText.lastIndexOf(' ');
              if (idx !== -1) {
                wraps.push(idx);
              }
            }
            if (Object.keys(heights).length > 2) {
              let max = Math.max(...Object.keys(heights));
              delete heights[max];
              let idx = wraps.shift(1);
              wraps = wraps.map(e => e - idx);
              spanNode.innerText = spanNode.innerText.slice(idx);
            }
          });          
          msg = msg.slice(1);
          let extra_wait = 0;
          if ('.!?'.indexOf(c) != -1 && ' '.indexOf(c_next) != -1) extra_wait = 700;
          if (',–;'.indexOf(c) != -1 && ' '.indexOf(c_next) != -1) extra_wait = 300;
          setTimeout(innerTypeWriter.bind(this, msg, spanNode, q_copy), msg.length > 0 ? that.speed + that.speed * Math.random()*3 + extra_wait : 0);
        } else {
          accept();
        }
      }
      innerTypeWriter(msg, spanNode, q_copy);
    });
  },
  async startTypeWriter(msg, withAudio) {
    let q_copy = q + 0;
    let msg_copy = ` ${msg}`.slice(1);
    await this.typeWriter(msg_copy, this.$refs.solvej_subtitle, q_copy);
    if (q_copy == q) {
      if (withAudio && !this.playing) this.setRiveValues({'Talking': false});
      if (!withAudio) this.setRiveValues({'Talking': false});
    }
    setTimeout(() => {
      this.stopSubtitles();
    }, 2000);
  }
}));