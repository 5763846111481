import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('swiper_helper', (swiperSettings) => ({
  swiperSettings: swiperSettings,
  swiperElement: null,
  init() {
    this.swiperElement = this.$el;
    Object.assign(this.swiperElement, this.swiperSettings);
    this.swiperElement.initialize();
  }
}));