import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('inview', (inview_classes) => ({
    inview_classes: inview_classes.split(' '),
    inview () {
      this.$refs.inview_elm.firstElementChild.classList.add(inview_classes);
    },
    outofview () {
      this.$refs.inview_elm.firstElementChild.classList.remove(inview_classes);
    }
}));