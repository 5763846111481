document.addEventListener('alpine:init', () => {
  Alpine.data('ticker', () => ({
    duration: 8000,
    active: 0,
    progress: 0,
    firstFrameTime: 0,
    init() {
      this.startAnimation()
      this.$watch('active', callback => {
        cancelAnimationFrame(this.frame)
        this.startAnimation()
      })
    },
    startAnimation() {
      this.widthOuter()
      this.animationItems()
      this.progress = 0
      this.$nextTick(() => {
        this.firstFrameTime = performance.now()
        this.frame = requestAnimationFrame(this.animate.bind(this))
      })
    },
    animate(now) {
      let timeFraction = (now - this.firstFrameTime) / this.duration
      if (timeFraction <= 1) {
        this.progress = timeFraction * 100
        this.frame = requestAnimationFrame(this.animate.bind(this))
      } else {
        timeFraction = 1
        this.active = (this.active + 1) % this.$refs.items.children.length
      }          
    },
    widthOuter() {
      this.$nextTick(() => {
        this.$refs.items.style.width = this.$refs.items.children.length * 100 + '%'
        this.$refs.items.style.minWidth = this.$refs.items.children.length * 100 + '%'
      })
    }, 
    animationItems() {
      this.$nextTick(() => {
        for (let i = 0; i < this.$refs.items.children.length; i++) {
          this.$refs.items.children[i].style.width = this.$refs.items.getBoundingClientRect().width / this.$refs.items.children.length + 'px';
          if (this.active === i){
            this.$refs.items.children[i].style.animationDuration = this.duration/1000 + 's'
            this.$refs.items.children[i].style.animationName = 'step'
          } else {
            this.$refs.items.children[i].style.animationDuration = ''
            this.$refs.items.children[i].style.animationName = ''
          }
        }
      })
    },
    onResize(e){
      this.widthOuter()
      this.animationItems()
    }
  }))
});
