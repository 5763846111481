import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('latestnews', () => ({
    breakpoints: [
        {
            range: [0, 640],
            slides: 12
          },
        {
            range: [641, 1024],
            slides: 6
          },
        {
            range: [1025, Infinity],
            slides: 4
          },
        ],
    current: 1,
    slides: 12,
    init () {
        this.resizeHandler({}, true);
    },
    resizeHandler (evt, init = false) {
        let width = window.innerWidth;
        this.slides = this.breakpoints.find(breakpoint => {
                return width >= breakpoint.range[0] && width <= breakpoint.range[1];
            })
            .slides;
    },
    scrollHandler (evt) {
        let scroll = evt.target.scrollLeft;
        let width = evt.target.scrollWidth;
        let slideWidth = width / this.slides;
        let current = Math.round(scroll / slideWidth) + 1;
        this.current = current;
    },
    slideTo (num) {
        let width = this.$refs.viewport.scrollWidth;
        let slideWidth = width / this.slides;
        this.$refs.viewport.scrollTo({
            left: (num - 1) * slideWidth,
            behavior: "smooth",
        });
    }
}));
