import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('horiz_slider', (configObj) => ({
  breakpoints: configObj.breakpoints,
  scrollItems: configObj.scroll_items,
  scrollSnap: configObj.scroll_snap,
  displayDots: configObj.display_dots,
  displayArrows: configObj.display_arrows,
  autoAdvanceTime: configObj.auto_advance_time,
  breakpoint: 'default',
  scrollIndex: 0,
  init () {
    const bps = this.sortByWidth(this.valToInt(this.parseTailwindTypeValues(this.breakpoints)));
    const sis = this.valToInt(this.parseTailwindTypeValues(this.scrollItems));
    this.lookupTable = this.createLookupTable(bps, sis);
    this.onResize();
    if (Number.isInteger(this.autoAdvanceTime) && this.autoAdvanceTime > 0) {
      this.startAutoAdvance()
    }
  },
  sortByWidth (bps) {
    return bps.sort((a, b) => a[1] - b[1]);
  },
  valToInt (bps) {
    return bps.map(e => {
      const [key, value] = e;
      return [key, parseInt(value, 10)];
    });
  },
  createLookupTable (bps, sis) {
    const table = [];
    for (let i = 0; i < sis.length; i++) {
      const [name, no_items] = sis[i];
      let entry = [];
      entry[0] = bps.find(e => e[0] == name)[1];
      entry[1] = i < sis.length - 1 ? bps.find(e => e[0] == sis[i+1][0])[1] : Number.POSITIVE_INFINITY;
      entry[2] = no_items;
      entry[3] = name;
      table.push(entry);
    }
    return table;
  },
  getCurrentBreakPoint (lt) {
    const w = window.innerWidth;
    for (let i = 0; i < lt.length; i++) {
      if (lt[i][0] <= w && w < lt[i][1]) {
        return lt[i][3];
      }
    }
  },
  getNumberOfScrollItems (bp, lt) {
    return this.lookupTable.find(e => e[3] == bp)[2];
  },
  parseTailwindTypeValues (str) {            
    return str.split(' ').map(v => {
      const [key, value] = v.split(':');
      if (value === undefined) {
        return ['default', key];
      }
      return [key, value];
    });
    return o;
  },
  onResize () {
    const bp = this.getCurrentBreakPoint(this.lookupTable);
    const num_si = this.getNumberOfScrollItems(bp, this.lookupTable);
    if (this.scrollSnap) this.setScrollSnap(num_si);
  },
  onScroll (from) {
    if (from == 'throttle') { // First scroll event
      if (!this.scrollInitiatedByScript) {
        this.stopAutoAdvance();
      }
    } else if (from == 'debounce') { // Last scroll event
      this.scrollEventCount = 0;
      this.scrollInitiatedByScript = false;
    }
    this.scrollIndex = this.getNearestScrollIndex();
  },
  setScrollSnap (num_si) {
    this.scrollPositions = [];
    Array.from(this.$refs.parent.children).forEach((e, i) => {
      if (i % num_si == 0) {
        this.scrollPositions.push(e.offsetLeft);
        e.style.scrollSnapStop = 'always';
        e.style.scrollSnapAlign = 'start';
      } else {
        e.style.scrollSnapStop = 'normal';
        e.style.scrollSnapAlign = 'none';
      }
    });
  },
  startAutoAdvance () {
    this.stopAutoAdvance();
    this.timer = setInterval(() => {
      this.next(true);
    }, this.autoAdvanceTime);
  },
  stopAutoAdvance () {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  snapToNearestScrollPosition () {
    const nearestScrollPosition = this.scrollPositions[this.getNearestScrollIndex()];
    this.scrollToPosition(nearestScrollPosition);
  },
  getNearestScrollIndex () {
    const currScrollAmount = this.$refs.scroller.scrollLeft;
    let bestIndex = 0;
    for (let i = 0; i < this.scrollPositions.length; i++) {
      const position = this.scrollPositions[i];
      if (currScrollAmount == position) {
        bestIndex = i;
      }
      if (currScrollAmount > position && i < this.scrollPositions.length - 1) {
        bestIndex = i + 1;
      }
    }
    return bestIndex;
  },
  next (autoAdvance = false) {
    this.scrollInitiatedByScript = autoAdvance;
    const currScrollAmount = this.$refs.scroller.scrollLeft;
    const maxScroll = this.$refs.scroller.scrollWidth - this.$refs.scroller.clientWidth;
    for (let i = 0; i < this.scrollPositions.length; i++) {
      const pos = this.scrollPositions[i];
      if (pos > currScrollAmount && currScrollAmount < maxScroll) {
        this.scrollToPosition(pos, !autoAdvance);
        return;
      }
    }
    this.scrollToPosition(0);
  },
  prev () {
    const currScrollAmount = this.$refs.scroller.scrollLeft;
    for (let i = this.scrollPositions.length - 1; i >= 0; i--) {
      const pos = this.scrollPositions[i];
      if (pos < currScrollAmount) {
        this.scrollToPosition(pos, true);
        return;
      }
    }
    this.scrollToPosition(this.scrollPositions[this.scrollPositions.length - 1]);
  },
  scrollToPosition (pos, stopAutoAdvance = false) {
    if (stopAutoAdvance) this.stopAutoAdvance();
    this.$refs.scroller.scroll({left: pos, behavior: 'smooth'});
  }
}));