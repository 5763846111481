import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import icon_lookup from "./YRIconLookup.js";
import {setCookie, getCookie} from "./CookieUtils.js";
import {supportsLocation, allowsLocation, getPlaceName, getLocationAndPlaceName, autoComplete} from "./LocationUtils.js";

Alpine.data('weatherApp', () => ({
  location: '',
  lat: 57.1669,
  lon: 9.7418,
  placename: 'Aabybro',
  country: '',
  state: '',
  searchVisible: false,
  lastModified: null,
  vvpHeight: window.visualViewport.height,
  supportsLocation: supportsLocation(),
  allowsLocation: false,
  geolocationLoading: false,
  geolocationActivated: false,
  forecastDays: [],
  presetLocations: [
    { name: 'Skagen', lat: 57.74089455879699, lon: 10.581549141046429 },
    { name: 'Frederikshavn', lat: 57.45160887522839, lon: 10.519260567109276 },
    { name: 'Brønderslev', lat: 57.269461090387786, lon: 9.9134789373724 },
    { name: 'Aalborg', lat: 57.04119675650484, lon: 9.933054347604132 },
    { name: 'Hobro', lat: 56.63833538757707, lon: 9.79357955565465 },
    { name: 'Aabybro', lat: 57.1499994, lon: 9.749997 },
    { name: 'Thisted', lat: 56.96123790398404, lon: 8.707144267706783 },
    { name: 'Aars', lat: 56.80348371686714, lon: 9.507289160928858 },
    { name: 'Læsø', lat: 57.27709356441343, lon: 10.993345824297588 },
  ],
  customLocations: [],
  locations: [],
  autoCompleteEntries: [],
  init () {
    allowsLocation()
    .then(state => this.allowsLocation = true)
    .catch(error => this.allowsLocation = false);
    let cl = getCookie('tv2nord_weather_custom_locations');
    let ll = getCookie('tv2nord_weather_last_location');
    if (cl) {
      this.customLocations = cl;
    }
    this.updateLocations();
    if (ll) {
      this.setLocation(ll.name, ll.lat, ll.lon, ll.country, ll.state);
    }
  },
  getWeatherForHere () {
    this.geolocationLoading = true;
    //getPlaceName(57.14396316153872, 9.72886367131302) // Jens
    //getPlaceName(57.04321055329106, 9.498229902937238) // Ude på landet
    //getPlaceName(55.68115027933368, 12.570829752923355) // Midt i København
    getLocationAndPlaceName()
    .then(data => {
      let state = data.address.state;
      let country = data.address.country;
      let name = '';
      if (data.address.town) {
        name = data.address.town;
      } else if (data.address.village) {
        name = data.address.village;
      } else if (data.address.neighbourhood) {
        name = data.address.neighbourhood;
      } else if (data.address.suburb) {
        name = data.address.suburb;
      } else if (data.address.district) {
        name = data.address.district;
      } else if (data.address.city) {
        name = data.address.city;
      } else if (data.address.road) {
        name = data.address.road;
      } else {
        name = data.display_name.split(',')[0];
      }
      this.setLocation(name, Number.parseFloat(data.lat), Number.parseFloat(data.lon), country, state);
      this.geolocationActivated = true;
      this.geolocationLoading = false;
    })
    .catch(error => {
      // User denied location access or network error
      this.geolocationActivated = true;
    });
  },
  setLocation(name, lat, lon, country, state) {
    this.placename = name;
    this.lat = lat;
    this.lon = lon;
    this.country = country;
    this.state = state;
    if (!this.locations.find(e => e.name == name)) {
      this.customLocations = this.customLocations.filter(e => e.name != name);
      this.customLocations.push({name, lat, lon});
      this.customLocations = this.customLocations.slice(-3);
      setCookie('tv2nord_weather_custom_locations', this.customLocations);
      requestAnimationFrame(() => {
        this.fetchWeatherData();
      });
    }
    setCookie('tv2nord_weather_last_location', {name, lat, lon});
    this.updateLocations();
  },
  updateLocations() {
    let locations = [...this.presetLocations];
    locations = locations.slice(0, this.presetLocations.length - this.customLocations.length);
    locations = locations.concat(this.customLocations);
    this.locations = locations;
  },
  getLocations() {
    return this.locations;
  },
  getWeatherForLocation (name) {
    // Reset geolocation activation when search is shown
    this.geolocationActivated = false;
    
    let loc = this.locations.find(e => e.name === name);
    this.setLocation(loc.name, loc.lat, loc.lon, loc.country, loc.state);
    requestAnimationFrame(() => {
      this.fetchWeatherData();
    });
  },
  setLocationFromCMS(locationName) {
    if (!locationName) return;
    
    // Use the autoComplete function to find the lat and lon for the locationName
    autoComplete(locationName).then(data => {
      if (data && data.length > 0) {
        const place = data[0];
        const lat = parseFloat(place.lat);
        const lon = parseFloat(place.lon);
        const name = place.address.city || place.address.name || place.display_name.split(",")[0];
        const country = place.address.country;
        const state = place.address.state;
  
        // Set the location with the retrieved coordinates, country and state
        this.setLocation(name, lat, lon, country, state);
        
        // Store country and state
        this.country = country;
        this.state = state;
  
        // Fetch the weather data after setting the location
        this.fetchWeatherData();
      } else {
        console.error("Could not find location data for the provided location name.");
      }
    }).catch(error => {
      console.error("Error fetching location data:", error);
    });
  },
  async fetchWeatherData() {
    try {
      const response = await fetch(`https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${this.lat}&lon=${this.lon}`, {
      });
      if (response.ok) {
        const currentModified = response.headers.get('Last-Modified');
        if (this.lastModified === currentModified) {
          return;
        }
        this.lastModified = currentModified;
        const data = await response.json();
        this.processWeatherData(data);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error(error);
    }
  },
  processWeatherData(data) {
    let timeseries = data.properties.timeseries;
    this.forecastDays = [];

    for (let i = 0; i < 10; i++) {
        let targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + i);

        // Call getForecastsForDay to get high/low temperatures, total precipitation, and hourly forecasts
        const dayForecast = this.getForecastsForDay(timeseries, targetDate);

        let dayData = {
            dayOfWeek: targetDate.toLocaleString('da-DK', { weekday: 'long' }),
            dayOfWeekNum: targetDate.getDay(),
            date: targetDate.getDate(),
            month: targetDate.toLocaleString('da-DK', { month: 'long' }),
            monthNum: targetDate.getMonth() + 1,
            year: targetDate.getFullYear(),
            highTemperature: dayForecast.highTemperature,  // Use highTemperature from getForecastsForDay
            lowTemperature: dayForecast.lowTemperature,    // Use lowTemperature from getForecastsForDay
            totalPrecipitation: dayForecast.totalPrecipitation, // Use totalPrecipitation from getForecastsForDay
            hourlyForecasts: dayForecast.hourlyForecasts,
            showHourly: false, // Initialize showHourly property
        };

        if (i === 0) {
            dayData.dayOfWeek = 'I dag';
        }

        // If there are hourly forecasts, update mid-day details
        if (dayData.hourlyForecasts.length > 0) {
            let midIndex = Math.floor(dayData.hourlyForecasts.length / 2);
            let midHour = dayData.hourlyForecasts[midIndex];

            // Already calculated highTemperature and lowTemperature, so no need to recalculate
            dayData.windSpeed = midHour.windSpeed;
            dayData.weatherIcon = midHour.weatherIcon;
            dayData.windDirection = midHour.windDirection;

            // Use hourlyPrecipitation from the first hour
            dayData.hourlyPrecipitation = Math.round(dayData.hourlyForecasts[0].hourlyPrecipitation);

            // Additional data for the first hour
            dayData.temperature = dayData.hourlyForecasts[0].temperature;
            dayData.currentWindspeed = dayData.hourlyForecasts[0].windSpeed;
            dayData.currentWindDirection = dayData.hourlyForecasts[0].windDirection;
            dayData.currentWeatherIcon = dayData.hourlyForecasts[0].weatherIcon;
        }

        // Add processed day data to forecastDays array
        this.forecastDays.push(dayData);
    }
  },

  
  getForecastsForDay(timeseries, targetDate) {
    // Filter timeseries data for the target day
    const dayForecasts = timeseries.filter(ts => {
        let tsDate = new Date(ts.time);
        return tsDate.getDate() === targetDate.getDate() && tsDate.getMonth() === targetDate.getMonth() && tsDate.getFullYear() === targetDate.getFullYear();
    });

    // Calculate high and low temperatures
    const temperatures = dayForecasts.map(ts => ts.data.instant.details.air_temperature);
    const highTemperature = Math.max(...temperatures);
    const lowTemperature = Math.min(...temperatures);

    // Calculate total precipitation for the day
    const totalPrecipitation = dayForecasts.reduce((total, ts) => {
      return total + this.getHourlyPrecipitation(ts); // Accumulate hourly precipitation
    }, 0); // Initialize the total as 0

    // Map the filtered data with necessary fields including high and low temperatures
    return {
        highTemperature,
        lowTemperature,
        totalPrecipitation,
        hourlyForecasts: dayForecasts.map(ts => ({
            time: new Date(ts.time).getHours(),
            temperature: ts.data.instant.details.air_temperature,
            windSpeed: ts.data.instant.details.wind_speed,
            weatherIcon: this.getWeatherIcon(ts),
            windDirection: ts.data.instant.details.wind_from_direction,
            hourlyPrecipitation: this.getHourlyPrecipitation(ts)
        }))
    };
  },

  getWeatherIcon(ts) {
    if (ts.data.next_1_hours && ts.data.next_1_hours.summary) {
      return this.getWeatherIconSvg(ts.data.next_1_hours.summary.symbol_code);
    } else if (ts.data.next_6_hours && ts.data.next_6_hours.summary) {
      return this.getWeatherIconSvg(ts.data.next_6_hours.summary.symbol_code);
    } else if (ts.data.next_12_hours && ts.data.next_12_hours.summary) {
      return this.getWeatherIconSvg(ts.data.next_12_hours.summary.symbol_code);
    } else {
      return '';
    }
  },

  getTotalPrecipitation(ts) {
     // Sum precipitation from next_1_hours, next_6_hours, and next_12_hours if available
     let totalPrecip = 0;

     if (ts.data.next_1_hours && ts.data.next_1_hours.details) {
         totalPrecip += ts.data.next_1_hours.details.precipitation_amount || 0;
     }
     if (ts.data.next_6_hours && ts.data.next_6_hours.details) {
         totalPrecip += ts.data.next_6_hours.details.precipitation_amount || 0;
     }
     if (ts.data.next_12_hours && ts.data.next_12_hours.details) {
         totalPrecip += ts.data.next_12_hours.details.precipitation_amount || 0;
     }
 
     return totalPrecip;
  },

  getHourlyPrecipitation(ts) {
    if (ts.data.next_1_hours && ts.data.next_1_hours.details) {
      return ts.data.next_1_hours.details.precipitation_amount || 0;
    } else if (ts.data.next_6_hours && ts.data.next_6_hours.details) {
      return ts.data.next_6_hours.details.precipitation_amount || 0;
    } else {
      return 0;
    }
  },

  getWeatherIconSvg(symbolCode) {
    return `<img src="/weather_svgs/${icon_lookup(symbolCode)}.svg" class="w-full h-auto" alt="${symbolCode}">`;
  },

  showSearch () {
    // Reset geolocation activation when search is shown
    this.geolocationActivated = false;

    this.searchVisible = true;

    let searchInput = document.getElementById('autoCompleteSearchInput');
    if (searchInput) {
      searchInput.focus();
    }
  },

  delayHideSearch () {
    this.hideSearchTimeout = setTimeout(() => {
      this.hideSearch();
    }, 100);
  },

  setLocationInterceptTimeout (name, lat, lon, country, state) {
    clearTimeout(this.hideSearchTimeout);
    this.setLocation(name, lat, lon, country, state);
    this.hideSearch();
  },

  hideSearch () {
    this.$refs.searchInput.value = '';
    this.autoCompleteEntries = [];
    this.searchVisible = false;
  },

  autoCompleteSearch(event) {
    if (event.target.value.length > 2) {
      autoComplete(event.target.value)
        .then(data => {
          console.log('Raw autocomplete data:', data);
          if (data && data.length > 0) {
            this.autoCompleteEntries = data;
            console.log('Filtered autocomplete entries:', this.autoCompleteEntries);
          } else {
            console.error("No autocomplete results found.");
            this.autoCompleteEntries = [];
          }
        })
        .catch(error => {
          console.error('Autocomplete error:', error);
          this.autoCompleteEntries = [];
        });
    } else {
      this.autoCompleteEntries = [];
    }
  }

}));
