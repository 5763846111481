import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('videoTable', (videoEntries = []) => ({
    videos: videoEntries,
    showModal: false,
    showMuxModal: false,
    selectedVideoHLS: '',
    muxPlaybackId: '',

    init () {
        window.Echo.channel('ovp-connect')
            .listen('.Tv2regionerne\\StatamicOvpConnect\\Events\\OvpEntryUpdated', (data) => {
                console.log('updated', data);
                this.updateVideo(data.entry);
            });
        this.sortVideos();
    },

    updateVideo (updatedVideo) {
        const index = this.videos.findIndex(video => video.id === updatedVideo.id);
        if (index !== -1) {
            console.log('Update video');
            this.videos[index] = updatedVideo;
        } else {
            console.log('Add video');
            this.videos.push(updatedVideo); // Add the new video if it doesn't exist
        }
        this.sortVideos();
    },

    openApiVideoEmbedUrl (videoId) {
        const embedUrl = `https://embed.api.video/vod/${videoId}`;
        window.open(embedUrl, '_blank');
    },

    openJWPlayerEmbedUrl (videoId) {
        const embedUrl = `https://cdn.jwplayer.com/players/${videoId}-NNs592Z4.html`;
        window.open(embedUrl, '_blank');
    },

    playVideo (hlsUrl) {
        if (hlsUrl) {
            this.selectedVideoHLS = hlsUrl;
            this.showModal = true;
            this.$nextTick(() => {
                this.$refs.videoPlayer.load(); // Sikrer at videoen indlæses efter URL er sat
            });
        }
    },

    closeModal () {
        if (this.$refs.videoPlayer) {
            this.$refs.videoPlayer.pause(); // Pause videoen
            this.$refs.videoPlayer.currentTime = 0; // Valgfrit: Nulstil videoen til start
            this.$refs.videoPlayer.src = ''; // Fjern video kilden
        }
        this.showModal = false; // Luk modalen
    },

    playMuxVideo (playbackId) {
        if (playbackId) {
            this.muxPlaybackId = playbackId;
            this.showMuxModal = true;
            this.$nextTick(() => {
                this.$refs.muxVideoPlayer.load(); // Sikrer at videoen indlæses efter URL er sat
            });
        }
    },

    closeMuxModal () {
        if (this.$refs.muxVideoPlayer) {
            this.$refs.muxVideoPlayer.pause(); // Pause videoen
            this.$refs.muxVideoPlayer.currentTime = 0; // Valgfrit: Nulstil videoen til start
            this.$refs.muxVideoPlayer.playbackId = ''; // Fjern video kilden
        }
        this.showMuxModal = false; // Luk modalen
    },

    sortVideos () {
        this.videos.sort((a, b) => {
            // Konverter til JavaScript Date objekter for sammenligning
            let dateA = new Date(a.updated_at);
            let dateB = new Date(b.updated_at);
            return dateB - dateA; // Sorter så den nyeste dato kommer først
        });
    },

}));
