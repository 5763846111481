import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('cookie_overview', () => ({
  toggleDetails (button) {
    const detailsDiv = button.target.parentNode.parentNode.nextElementSibling;
    let currentText = button.target.innerHTML;

    if(currentText === "Mere") {
      button.target.innerHTML = "Mindre";
    } else {
      button.target.innerHTML = "Mere";
    }
    detailsDiv.classList.toggle('hidden');
  }
}));
