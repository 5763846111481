import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('scrollText', () => ({
    count: 0,
    init() {
        const rootWidth = this.$root.offsetWidth;
        const textWidth = this.$refs.text.offsetWidth;
        this.count = Math.ceil(rootWidth / textWidth) + 1;
        this.$refs.inner.animate([
            { transform: `translateX(0px)` },
            { transform: `translateX(-${textWidth}px)` }
        ], {
            duration: 3000,
            iterations: Infinity
        });
    },
}));