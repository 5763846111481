import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('backButton', () => ({
    style: { transform: 'none' },
    init () {
        window.addEventListener('navVisibilityChange', this.navVisibilityChangeHandler.bind(this));
    },
    navVisibilityChangeHandler (evt) {
        this.style.transform = evt.detail.hidden ? `translateY(100px)` : 'none'
    },
    goBack () {
        window.history.back();
    }
}));
